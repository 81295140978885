.landscape__skyline {
  .landscape:not(.dark) & {
    --skyline-1-color: #a0bfd7;
    --skyline-2-color: #b7d2e5;
    --skyline-3-color: #cfddf2;
    --skyline-window-color: #dff2f2;
    --skyline-window-color-lights: #dff2f2;
  }

  .landscape.dark & {
    --skyline-1-color: #505b75;
    --skyline-2-color: #5d6b8a;
    --skyline-3-color: #6d7b97;
    --skyline-window-color: #4d5555;
    --skyline-window-color-lights: #d9efff;
  }

  .color-1 {
    fill: var(--skyline-1-color);
  }

  .color-2 {
    fill: var(--skyline-2-color);
  }

  .color-3 {
    fill: var(--skyline-3-color);
  }

  .window {
    fill: var(--skyline-window-color);
  }

  .hill-1 {
    bottom: 4.4%;
    left: 7.45%;
  }

  .hill-2 {
    right: 0;
    bottom: 29%;
  }

  .windmill-1 {
    bottom: 43%;
    left: 21.45%;
  }

  .windmill-2 {
    bottom: 40%;
    left: 19%;
  }

  .windmill-3 {
    right: 9%;
    bottom: 50%;
  }

  .buildings-1 {
    bottom: 25%;
    left: 35.5%;
  }

  .buildings-2 {
    bottom: 25%;
    left: 25%;
  }

  .buildings-3 {
    right: 16%;
    bottom: 27%;
  }

  .buildings-4 {
    right: 22%;
    bottom: 24%;
  }

  .window-1,
  .window-2 {
    left: 33.8%;
  }

  .window-1 {
    bottom: 43%;
  }

  .window-2 {
    bottom: 39%;
  }

  .window-3 {
    bottom: 42%;
    left: 31.4%;
  }

  .window-4 {
    bottom: 39%;
    left: 43.4%;
  }

  .window-5,
  .window-6 {
    right: 30.3%;
  }

  .window-5 {
    bottom: 45%;
  }

  .window-6 {
    bottom: 39%;
  }
}

.dark .landscape__skyline .lights-on {
  fill: var(--skyline-window-color-lights);
  filter: drop-shadow(0 0 3px var(--windmill-window-color-lights));
}
