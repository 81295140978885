body {
  margin: 0;
  padding: 0;
}

.new-item-date-calendar {
  position: absolute;
  margin-top: var(--hcr-spacing-2);
  width: 300px;
}

.theme-switcher-wrapper {
  height: 100%;
  display: grid;
  align-content: center;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  flex-flow: column;
}

.loader-container p {
  color: white;
  font-size: 1.5rem;
}

.shadow {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, .06), 0px 3px 18px rgba(0, 0, 0, .06), 0px 3px 6px rgba(0, 0, 0, .18);
}

.border-0 {
  border: 0px;
}

.border-1 {
  border: 1px solid #c8c8c8;
}

.secondary {
  background-color: #a2e53f;
}

.primary {
  background-color: #ffcb46;
}

.danger {
  background-color: #dd2828;
}

.radioFlex {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

#file {
  opacity: 0;
  position: absolute;
  z-index: -1;
}