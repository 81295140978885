@import '../../../node_modules/@flixbus/honeycomb-react/dist/scss/honeycomb-tools.scss';

.platform-footer {
  position: absolute;
  overflow: hidden;
  pointer-events: none; // allow clicks if footer image overlaps content because of negative margins and transforms
  bottom: 0;
  left: 0;
  right: 0;

  &__bar {
    padding: cssvar(spacing-2);
    border-top: 1px solid cssvar(primary-line-color);
    background: cssvar(primary-bg-color);
    pointer-events: all;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: cssvar(spacing-5); // sass-lint:disable-line no-misspelled-properties
    row-gap: cssvar(spacing-2); // sass-lint:disable-line no-misspelled-properties
    flex-wrap: wrap;
  }

  &__made-with-honey {
    color: cssvar(secondary-content-color);
    margin-bottom: 0;
    flex-shrink: 0;
  }

  &__credits-nav {
    border-bottom: 0;

    ul:first-child,
    li {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &__switchers {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: cssvar(spacing-2); // sass-lint:disable-line no-misspelled-properties
  }

  @include on-bp(md) {
    &__container {
      flex-wrap: nowrap;
      justify-content: flex-start;
    }

    &__switchers {
      justify-content: flex-end;
    }
  }
}
