.landscape__town-buildings {
  .landscape:not(.dark) & {
    --town-color-1: #cfddf2;
    --town-color-2: #a0bfd7;
    --town-color-3: #6980a4;
    --town-color-roof-1: #191e4d;
    --town-color-roof-2: #343d56;
    --town-highlights: #fff;
    --town-window-1: #6980a4;
    --town-window-2: #343d56;
    --town-window-lights: #6980a4;
  }

  .landscape.dark & {
    --town-color-1: #6d7b97;
    --town-color-2: #505b75;
    --town-color-3: #212730;
    --town-color-roof-1: #000;
    --town-color-roof-2: #1a1c20;
    --town-highlights: #8d9ab2;
    --town-window-1: #212730;
    --town-window-2: #1a1c20;
    --town-window-lights: #e7e6b2;
  }

  bottom: 10px;
  left: 22.88%;

  .house-1 {
    margin-right: -3px;
  }

  .park {
    position: relative;
    margin-right: -10px;

    & > svg:first-of-type {
      margin-right: -7px;
    }
  }

  .house-2 {
    margin-right: -6px;
  }

  .house-3 {
    margin-right: -8px;

    & > svg:first-of-type {
      margin-right: -4px;
    }
  }

  .color-1 {
    fill: var(--town-color-1);
  }

  .color-2 {
    fill: var(--town-color-2);
  }

  .color-3 {
    fill: var(--town-color-3);
  }

  .roof-1 {
    fill: var(--town-color-roof-1);
  }

  .roof-2 {
    fill: var(--town-color-roof-2);
  }

  .highlights {
    fill: var(--town-highlights);
  }

  .window {
    fill: var(--town-window-1);
  }

  .window-2 {
    fill: var(--town-window-2);
  }

  .tree-1 {
    fill: var(--hills-1-color);
  }

  .tree-2 {
    fill: var(--hills-2-color);
  }
}

.dark .landscape__town-buildings .lights-on {
  fill: var(--town-window-lights);
  filter: drop-shadow(0 0 3px var(--town-window-lights));
}
