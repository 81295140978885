.landscape__railroad {
  .landscape:not(.dark) & {
    --railroad-base-color: #343d56;
    --railroad-track-color: #6980a4;
    --railroad-rails-1-color: #373940;
    --railroad-rails-2-color: #0d0d0d;
  }

  .landscape.dark & {
    --railroad-base-color: #1a1c20;
    --railroad-track-color: #212730;
    --railroad-rails-1-color: #1c1d20;
    --railroad-rails-2-color: #000;
  }

  bottom: 2.9%;
  white-space: nowrap;

  .railroad-track {
    width: 100%;
    height: 1px;
    margin-bottom: -1px;
    border-top: solid 2px var(--railroad-rails-2-color);
    border-bottom: solid 8px var(--railroad-track-color);
    background: repeating-linear-gradient(
      to right,
      var(--railroad-rails-1-color) 0,
      var(--railroad-rails-1-color) 4px,
      transparent 4px,
      transparent 10px);
    box-sizing: content-box;
  }

  .railroad-base {
    margin-right: -2px;
    fill: var(--railroad-base-color);
  }
}

.landscape__train {
  .landscape:not(.dark) & {
    --train-color: #73d700;
    --train-color-shade: #007131;
    --train-lettering-opacity: 1;
    --train-window: #6980a4;
    --train-steel-1: #6980a4;
    --train-steel-2: #505b75;
    --train-steel-3: #343d56;
    --train-steel-4: #495b87;
    --train-steel-5: #1a202d;
  }

  .landscape.dark & {
    --train-color: #475c2d;
    --train-color-shade: #051100;
    --train-lettering-opacity: .4;
    --train-window: #e7e6b2;
    --train-steel-1: #212730;
    --train-steel-2: #232733;
    --train-steel-3: #1a1c20;
    --train-steel-4: #2b303f;
    --train-steel-5: #000;
  }

  width: 430px;
  height: 41px;
  // transform: translateX(220%);
  transform: translateX(800%);
  animation-name: travel-left;
  animation-duration: 13s;
  animation-delay: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  // animation-play-state: paused;

  .train-color {
    fill: var(--train-color);
  }

  .window {
    fill: var(--train-window);
  }

  .train-color-shade {
    fill: var(--train-color-shade);
  }

  .train-steel-1 {
    fill: var(--train-steel-1);
  }

  .train-steel-2 {
    fill: var(--train-steel-2);
  }

  .train-steel-3 {
    fill: var(--train-steel-3);
  }

  .train-steel-4 {
    fill: var(--train-steel-4);
  }

  .train-steel-5 {
    fill: var(--train-steel-5);
  }

  .flixtrain-lettering {
    position: absolute;
    opacity: var(--train-lettering-opacity);
    pointer-events: none;
    user-select: none;
  }

  .locomotive,
  .wagon {
    display: inline-block;
    position: relative;
    margin-right: -1px;
  }

  .locomotive .flixtrain-lettering {
    top: 33%;
    left: 31.5%;
  }

  .wagon .flixtrain-lettering {
    top: 49.5%;
    left: 12%;
    transform: scale(.55);

    &:last-of-type {
      right: 5%;
      left: unset;
    }
  }
}

.dark .landscape__train .window {
  filter: drop-shadow(0 0 3px var(--train-window));
}

@keyframes travel-left {
  from {
    transform: translateX(700%);
  }

  to {
    transform: translateX(-110%);
  }
};

