.landscape__green-hills {
  .landscape:not(.dark) & {
    --hills-1-color: #72c900;
    --hills-1-color-shadow: #2b9900;
    --hills-2-color: #a1ea2d;
    --hills-2-color-shadow: #73d700;
  }

  .landscape.dark & {
    --hills-1-color: #475c2d;
    --hills-1-color-shadow: #0c2b00;
    --hills-2-color: #556141;
    --hills-2-color-shadow: #0c2b00;
  }

  right: 0;
  bottom: 16px;
  left: 0;

  svg:not(.landscape__windmill) {
    position: absolute;
    bottom: 0;
  }

  .color-1 {
    fill: var(--hills-1-color);
  }

  .color-shadow-1 {
    fill: var(--hills-1-color-shadow);
  }

  .color-2 {
    fill: var(--hills-2-color);
  }

  .color-shadow-2 {
    fill: var(--hills-2-color-shadow);
  }

  .green-hills-1 {
    bottom: 0;
    left: 4.87%;
  }

  .green-hills-2 {
    right: 4.53%;
    bottom: 0;
  }

  .green-hills-2-shadow {
    right: 4.37%;
    bottom: 0;
  }

  .green-hills-3 {
    bottom: 0;
    left: 0;
  }

  .green-hills-3-shadow {
    bottom: 0;
    left: 0;
  }

  .green-hills-4 {
    right: 0;
    bottom: 0;
  }
}

.landscape__windmill {
  .landscape:not(.dark) & {
    --windmill-body-1-color: #a0bfd7;
    --windmill-body-2-color: #cfddf2;
    --windmill-body-details-color: #6980a4;
    --windmill-body-highlights-color: #fff;
    --windmill-base-color: #daf4c9;
    --windmill-window-color: #343d56;
    --windmill-window-color-lights: #343d56;
  }

  .landscape.dark & {
    --windmill-body-1-color: #505b75;
    --windmill-body-2-color: #6d7b97;
    --windmill-body-details-color: #212730;
    --windmill-body-highlights-color: #8d9ab2;
    --windmill-base-color: #586065;
    --windmill-window-color: #1a1c20;
    --windmill-window-color-lights: #dff2f2;
  }

  position: absolute;
  bottom: 37px;
  left: 10.87%;

  .body-color-1 {
    fill: var(--windmill-body-1-color);
  }

  .body-color-2 {
    fill: var(--windmill-body-2-color);
  }

  .body-color-details {
    fill: var(--windmill-body-details-color);
  }

  .body-color-highlights {
    fill: var(--windmill-body-highlights-color);
  }

  .base-color {
    fill: var(--windmill-base-color);
  }

  .window-color {
    fill: var(--windmill-window-color);
  }
}

.dark .landscape__windmill .lights-on {
  fill: var(--windmill-window-color-lights);
  filter: drop-shadow(0 0 3px var(--windmill-window-color-lights));
}

