@import '../../../../node_modules/@flixbus/honeycomb-react/dist/scss/honeycomb-tools';

.landscape {
  position: relative;
  width: 1366px;
  height: 290px;
  margin: 0 auto;
  overflow: hidden;
  transform: scale(.85) translate(-50%, 8.5%);

  @include on-bp(sm) {
    transform: scale(.85) translate(-15%, 8.5%);
  }

  @include on-bp(lg) {
    transform: scale(.95) translate(-5%, 2.5%);
  }

  @include on-bp(xl) {
    transform: scale(1) translate(0);
  }

  & > svg,
  & > div {
    position: absolute;
  }

  &__clouds,
  &__skyline {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    svg {
      position: absolute;
    }
  }
}
