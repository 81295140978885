.landscape__road {
  .landscape:not(.dark) & {
    --road-color-1: #6980a4;
    --road-color-2: #a0bfd7;
    --bridge-base: #6980a4;
    --bridge-bricks: #2b3d5c;
    --bridge-rails: #a0bfd7;
    --bridge-poles: #fff;

    --auto-color-1: #cfddf2;
    --auto-color-2: #a0bfd7;
    --auto-color-3: #6980a4;
    --auto-tires-color: #343d56;
    --auto-red-lights: #f9612b;
    --auto-highlights: #fff;
    --auto-windows: #495b87;
    --auto-details: #2b3d5c;
  }

  .landscape.dark & {
    --road-color-1: #212730;
    --road-color-2: #505b75;
    --bridge-base: #212730;
    --bridge-bricks: #1a1c20;
    --bridge-rails: #505b75;
    --bridge-poles: #8d9ab2;

    --auto-color-1: #6d7b97;
    --auto-color-2: #505b75;
    --auto-tires-color: #1a1c20;
    --auto-red-lights: #290c00;
    --auto-red-lights-on: #f9612b;
    --auto-highlights: #8d9ab2;
    --auto-windows: #2b303f;
    --auto-details: #1a1c20;
  }

  right: 0;
  bottom: 0;
  left: 0;

  & svg {
    display: block;
    position: absolute;
  }

  .street,
  .bridge {
    right: 0;
    bottom: 0;
    left: 0;

    .color-1 {
      fill: var(--road-color-1);
    }

    .color-2 {
      fill: var(--road-color-2);
    }

    .bridge-base {
      fill: var(--bridge-base);
    }

    .bridge-brick {
      fill: var(--bridge-bricks);
    }

    .bridge-pole {
      fill: var(--bridge-poles);
    }

    .bridge-rails {
      fill: var(--bridge-rails);
    }
  }

  .auto {
    bottom: 15px;

    &.car-1 {
      left: 18%;
    }

    &.car-2 {
      left: 63%;
    }

    &.van {
      left: 40%;
    }

    .color-1 {
      fill: var(--auto-color-1);
    }

    .color-2 {
      fill: var(--auto-color-2);
    }

    .color-3 {
      fill: var(--auto-color-3);
    }

    .window {
      fill: var(--auto-windows);
    }

    .tires {
      fill: var(--auto-tires-color);
    }

    .highlights {
      fill: var(--auto-highlights);
    }

    .red-lights {
      fill: var(--auto-red-lights);
    }

    .detail {
      fill: var(--auto-details);
    }
  }
}


.dark .landscape__road .van .red-lights {
  filter: drop-shadow(0 0 3px var(--auto-red-lights-on));
  animation: blink 2s infinite both steps(2);
}

@keyframes blink {
  from {
    fill: var(--auto-red-lights-on);
  }

  to {
    fill: var(--auto-red-lights);
  }
}
