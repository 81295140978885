.landscape__sky {
  .landscape:not(.dark) & {
    --sky-1-color: #dff2f2;
    --sky-2-color: #e7eef2;
    --sky-3-color: #b7d2e5;
    --sky-4-color: #dae7ef;
  }

  .landscape.dark & {
    --sky-1-color: #7281a0;
    --sky-2-color: #6980a4;
    --sky-3-color: #566681;
    --sky-4-color: #60718d;
  }

  top: 0.01%;
  right: 5.37%;
  bottom: 5.3%;
  left: 5.32%;

  .sky-1 {
    fill: var(--sky-1-color);
  }

  .sky-2 {
    fill: var(--sky-2-color);
  }

  .sky-3 {
    fill: var(--sky-3-color);
  }

  .sky-4 {
    fill: var(--sky-4-color);
  }
}

.landscape__clouds {
  .landscape:not(.dark) & {
    --cloud-1-color: #dae7ef;
    --cloud-2-color: #fff;
    --cloud-3-color: #dff2f2;
  }

  .landscape.dark & {
    --cloud-1-color: #a2acbe;
    --cloud-2-color: #8d9ab2;
    --cloud-3-color: #6f7f9e;
  }

  & > svg {
    opacity: 0;
    animation: float ease-in-out infinite;
  }

  .cloud-2,
  .cloud-4,
  .cloud-5,
  .cloud-6 {
    fill: var(--cloud-2-color);
  }

  .cloud-1 {
    top: 48.77%;
    left: 5.28%;
    fill: var(--cloud-1-color);
    animation-duration: 8s;
  }

  .cloud-2 {
    top: 16.96%;
    left: 21.46%;
    animation-delay: .5s;
    animation-duration: 10s;
  }

  .cloud-3 {
    top: 18.37%;
    left: 32.54%;
    fill: var(--cloud-3-color);
    animation-delay: 1s;
    animation-duration: 11s;
  }

  .cloud-4 {
    top: 21.6%;
    left: 82.27%;
    animation-delay: 1.7s;
    animation-duration: 9s;
  }

  .cloud-5 {
    top: 30.71%;
    left: 46.11%;
    animation-delay: 2s;
    animation-duration: 10s;
  }

  .cloud-6 {
    top: 26.28%;
    left: 60.84%;
    animation-delay: 2.35s;
    animation-duration: 8s;

  }
}

@keyframes float {
  0% {
    transform: translateX(0);
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  75% {
    opacity: 1;
  }

  100% {
    transform: translateX(-150%);
    opacity: 0;
  }
}

