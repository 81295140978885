.landscape__bus {
  .landscape:not(.dark) & {
    --bus-dark-blue-color: #191e4d;
    --bus-windows-base-blue: #2b3d5c;
    --bus-blue-highlight: #485b7d;
    --bus-bumper-1: #d9efff;
    --bus-bumper-2: #a0c2d9;
    --bus-bumper-green: #007131;
    --bus-front-lights: #fff;
    --bus-wheels-highlight: #fff;
    --bus-tires-1: #6980a4;
    --bus-tires-2: #cfddf2;
    --bus-dark-green-color: #00a72f;
    --bus-medium-green-color: #2b9900;
    --bus-light-green-color: #73d700;
    --bus-lettering-opacity: 1;
    --bus-red-light: #bd1622;
  }

  .landscape.dark & {
    --bus-dark-blue-color: #000;
    --bus-windows-base-blue: #1a1c20;
    --bus-blue-highlight: #252525;
    --bus-bumper-1: #4d565c;
    --bus-bumper-2: #35393b;
    --bus-bumper-green: #313b2e;
    --bus-front-lights: #fcfbb7;
    --bus-wheels-highlight: #8d9ab2;
    --bus-tires-1: #212730;
    --bus-tires-2: #6d7b97;
    --bus-dark-green-color: #161f15;
    --bus-medium-green-color: #1b3115;
    --bus-light-green-color: #475c2d;
    --bus-lettering-opacity: .4;
    --bus-red-light: #f9612b;
  }

  .dark-blue-color {
    fill: var(--bus-dark-blue-color);
  }

  .blue-highlight {
    fill: var(--bus-blue-highlight);
  }

  .bumper-1 {
    fill: var(--bus-bumper-1);
  }

  .bumper-2 {
    fill: var(--bus-bumper-2);
  }

  .bumper-green {
    fill: var(--bus-bumper-green);
  }

  .dark-green-color {
    fill: var(--bus-dark-green-color);
  }

  .medium-green-color {
    fill: var(--bus-medium-green-color);
  }

  .light-green-color {
    fill: var(--bus-light-green-color);
  }

  .window-base-blue {
    fill: var(--bus-windows-base-blue);
  }

  .front-lights {
    fill: var(--bus-front-lights);
  }

  .wheels-highlight {
    fill: var(--bus-wheels-highlight);
  }

  .tires-1 {
    fill: var(--bus-tires-1);
  }

  .tires-2 {
    fill: var(--bus-tires-2);
  }

  .red-lights {
    fill: var(--bus-red-light);
  }

  .flixbus-lettering {
    opacity: var(--bus-lettering-opacity);
  }

  bottom: 5px;
  left: 50.5%;
}

.dark .landscape__bus {
  .front-lights  {
    filter: drop-shadow(2px 0 1px var(--bus-front-lights))
    drop-shadow(2px 0 1px var(--bus-front-lights))
    drop-shadow(2px 0 1px var(--bus-front-lights))
    drop-shadow(2px 0 1px var(--bus-front-lights))
    drop-shadow(2px 0 1px var(--bus-front-lights));
  }

  .red-lights {
    filter: drop-shadow(2px 0 1px var(--bus-red-light));
  }
}

